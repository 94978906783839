
import http from "@/http";
import { Breadcrumb, Key } from "@/interfaces";
import Vue from "vue";

export interface DataType {
  key?: Key;
}

export default Vue.extend({
  data(): DataType {
    return {
      key: undefined,
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.key == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings",
        },
        {
          text: `${this.key.building.name}`,
          disabled: false,
          to: `/building/${this.key.building.id}`,
        },
        {
          text: "共用部カギ",
          disabled: false,
          to: `/building/${this.key.building.id}/key`,
        },
        {
          text: `#${this.key.id} ${this.key.name}`,
          disabled: true,
        },
      ];
    },
  },
  async mounted() {
    this.key = undefined;

    const { id } = this.$route.params;

    const url = `keys/${id}`;

    const { data } = await http.get<Key>(url);

    this.key = data;
  },
  methods: {
    async remove() {
      if (!window.confirm("本当に削除しますか?") || this.key == null) {
        return;
      }

      const url = `keys/${this.key.id}`;

      const path = `/building/${this.key.building_id}/key`;

      await http.delete(url, {});

      await await this.$router.push({
        path,
      });
    },
  },
});
